<template>
    <article v-if="pacchetto">
        <section class="pacchettoInfo">
            <div class="cont">
                <div class="colonne">
                    <div class="innerCont colonnaMain">

                        <!-- BODY -->
                        <ModuloBody v-if="pacchetto.translations && pacchetto.translations[0]?.body" :body="pacchetto.translations[0]?.body" />

                        <!-- IMMAGINI -->
                        <ModuloImmagine v-if="pacchetto.media && pacchetto.media.length == 1" :image="pacchetto.media[0]" />
                        <ModuloMedia v-else-if="pacchetto.media && pacchetto.media.length > 1" :media="pacchetto.media" />

                        <!-- CONTATTI -->
                        <SezioneShare />
                        <LazySkipHydration>
                            <LazySezioneContatti :contatti="pacchetto" />
                        </LazySkipHydration>

                        <!-- ORARI / TARIFFE / INFORMAZIONI -->
                        <SezioneCardEvento :evento="pacchetto" />
                        <SezioneAccordionPacchetto :pacchetto="pacchetto" />

                    </div>
                    <aside>

                        <!-- PRODOTTI -->
                        <ListaSideProdotti v-if="pacchetto.aderisce_a && pacchetto.aderisce_a.length > 0" :prodotti="pacchetto.aderisce_a" />

                    </aside>
                </div>
            </div>
        </section>

        <!-- MAPPA -->
        <MappaCoordinate v-if="pacchetto.geolocalizzazione && pacchetto.geolocalizzazione.coordinates" :latitude="pacchetto.geolocalizzazione.coordinates[1]" :longitude="pacchetto.geolocalizzazione.coordinates[0]" />

        <!-- OPERATORE -->
        <ListaOperatore v-if="pacchetto.operatore" :operatore="pacchetto.operatore" type="operatori_incoming" />

        <!-- ACCORDION TARGET -->
        <SezioneAccordionTarget :risorsa="pacchetto" />

        <!-- EVENTI / POTREBBE INTERESSARTI -->
        <ModuloCarousel v-if="moduloRelated" :modulo="moduloRelated" size="small" />

        <!-- PAGINE COLLEGATE -->
        <ListaPagineCollegate v-if="pacchetto.pagine_collegate && pacchetto.pagine_collegate.length > 0" :pagine="pacchetto.pagine_collegate" />

    </article>
</template>

<script lang="ts" setup>
const { localeProperties, t } = useI18n()
const { seEventoPassato, sortEventiByOrario } = useOrari()
const { findStartDate, findEndDate } = useSchema()
const { createPacchettiRoutes, dizionario } = useSitemap()
const setI18nParams = useSetI18nParams()
const directusUrl = useDirectusUrl()
const route = useRoute()
const props = defineProps<{ pacchetto: any, routes: any }>()

const moduloRelated = computed(() => {
    if ((props.pacchetto.eventi && props.pacchetto.eventi.length > 0) || (props.pacchetto.risorse && props.pacchetto.risorse.length > 0)) {

        const risorse = props.pacchetto.risorse && props.pacchetto.risorse.length > 0 ? props.pacchetto.risorse : []
        const sortedEventi = props.pacchetto.eventi && props.pacchetto.eventi.length > 0 ? sortEventiByOrario(props.pacchetto.eventi, 'eventi_id', 'asc') : []

        return {
            contenuto: 'manuale',
            translations: [
                {
                    titolo: t('la_proposta_comprende')
                }
            ],
            card: [
                ...risorse.map((r: any) => {
                    return {
                        item: {
                            __typename: 'risorse',
                            ...r.risorse_id
                        }
                    }
                }),
                ...sortedEventi.map((e: any) => {
                    return {
                        item: {
                            __typename: 'eventi',
                            ...e
                        }
                    }
                })
            ]
        }
    } else return null
})

useHead({
    link: [{ rel: 'canonical', href: `https://turismotorino.org${route.path.replace(/\/$/, "")}` }]
});
useSeoMeta({
    title: () => props.pacchetto && props.pacchetto.translations && props.pacchetto.translations[0]?.og_title ? `${props.pacchetto.translations[0].og_title} | ${props.pacchetto.tipologia ? t(props.pacchetto.tipologia) : t('eventi')} | Turismo Torino e Provincia` : null,
    ogTitle: () => props.pacchetto && props.pacchetto.translations && props.pacchetto.translations[0]?.og_title ? `${props.pacchetto.translations[0].og_title} | ${props.pacchetto.tipologia ? t(props.pacchetto.tipologia) : t('eventi')} | Turismo Torino e Provincia` : null,
    twitterTitle: () => props.pacchetto && props.pacchetto.translations && props.pacchetto.translations[0]?.og_title ? `${props.pacchetto.translations[0].og_title} | ${props.pacchetto.tipologia ? t(props.pacchetto.tipologia) : t('eventi')} | Turismo Torino e Provincia` : null,

    description: () => props.pacchetto && props.pacchetto.translations && props.pacchetto.translations[0]?.og_description ? props.pacchetto.translations[0].og_description : null,
    ogDescription: () => props.pacchetto && props.pacchetto.translations && props.pacchetto.translations[0]?.og_description ? props.pacchetto.translations[0].og_description : null,
    twitterDescription: () => props.pacchetto && props.pacchetto.translations && props.pacchetto.translations[0]?.og_description ? props.pacchetto.translations[0].og_description : null,

    ogImage: () => props.pacchetto && props.pacchetto.cover && props.pacchetto.cover.height && props.pacchetto.cover.width ? `${directusUrl}assets/${props.pacchetto.cover.id}?width=1600&format=jpeg` : null,
    twitterImage: () => props.pacchetto && props.pacchetto.cover && props.pacchetto.cover.height && props.pacchetto.cover.width ? `${directusUrl}assets/${props.pacchetto.cover.id}?width=1600&format=jpeg` : null,

    ogLocale: () => localeProperties.value.isold,
})

if (props.routes && route.name.indexOf('preview') != 0) setI18nParams(props.routes)

// TODO jsonld?
</script>

<style lang="scss" scoped>
article {
    margin: var(--article-top) 0 var(--article-bottom) 0;
    display: flex;
    flex-direction: column;
    gap: var(--article-gap-outer);

    .pacchettoInfo {
        .colonne {
            display: flex;
            justify-content: space-between;
            gap: var(--margin-double);

            .colonnaMain {
                display: flex;
                flex-direction: column;
                gap: var(--article-gap-outer);
                width: 100%;
            }

            &:deep(.cont) {
                padding: 0;
            }
        }
    }
}

@media screen and (max-width: 1060px) {
    article {
        .pacchettoInfo {
            .colonne {
                flex-direction: column;
            }
        }
    }
}
</style>